import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Link,
  SimpleGrid,
} from "@chakra-ui/react";
import { SiEtsy } from "react-icons/si";

const Shop = () => {
  // Define an array of objects with book data
  const books = [
    {
      title: "The Gates of Hell",
      bookNumber: "- Book 0.5",
      link: "https://mybook.to/tgoh",
      imageUrl: "/book05cover.png",
    },
    {
      title: "The River of Fire",
      bookNumber: "- Book 1",
      link: "https://mybook.to/trof",
      imageUrl: "/book1cover.png",
    },
    {
      title: "The Realm of Shadows",
      bookNumber: "- Book 1.5",
      link: "https://mybook.to/tros",
      imageUrl: "/book15cover.png",
    },
    {
      title: "A Hellish Christmas Short Story Duology",
      bookNumber: "- TROH Christmas Novelette",
      link: "https://mybook.to/hellishchristmas",
      imageUrl: "/novelettecover.png",
    },
    {
      title: "The Gates of Hell",
      bookNumber: "- Paperback",
      link: "https://www.lulu.com/shop/liana-valerian/the-gates-of-hell/paperback/product-q65ernd.html?q=liana+valerian&page=1&pageSize=4",
      imageUrl: "/goh-cover.jpg",
    },
    {
      title: "The Gates of Hell",
      bookNumber: "- Hardcover",
      link: "https://www.lulu.com/shop/liana-valerian/the-gates-of-hell/hardcover/product-jezkvn7.html?q=liana+valerian&page=1&pageSize=4",
      imageUrl: "/goh-cover.jpg",
    },
    {
      title: "The River of Fire",
      bookNumber: "- Paperback",
      link: "https://www.lulu.com/shop/liana-valerian/the-river-of-fire/paperback/product-45rzwqj.html?q=liana+valerian&page=1&pageSize=4",
      imageUrl: "/rof-cover.jpg",
    },
    {
      title: "The River of Fire",
      bookNumber: "- Hardcover",
      link: "https://www.lulu.com/shop/liana-valerian/the-river-of-fire/hardcover/product-q65mv2w.html?q=liana+valerian&page=1&pageSize=4",
      imageUrl: "/rof-cover.jpg",
    },
  ];

  return (
    <Box py={10} bg="transparent">
      <Container
        maxW="container.xl"
        display="flex"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
      >
        <Heading as="h1" size="xl" mb={6} color="white">
          Shop
        </Heading>
        <Link
          href="https://www.etsy.com/shop/LianaValerian?ref=seller-platform-mcnav"
          isExternal
          display="flex"
          alignItems="center"
          color="orange.600"
          fontSize="lg"
          fontWeight="bold"
          marginBottom={8}
        >
          <SiEtsy style={{ marginRight: "8px" }} />{" "}
          <Text style={{ color: "white" }}>Check out our Etsy store!</Text>
        </Link>
        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          spacing={10}
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
          alignItems="stretch"
        >
          {books.map((book, index) => (
            <ShopItem
              key={index}
              title={book.title}
              bookNumber={book.bookNumber}
              link={book.link}
              imageUrl={book.imageUrl}
            />
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

const ShopItem = ({ title, link, imageUrl, bookNumber }) => {
  return (
    <Box
      bg="rgba(255, 255, 255, 0.6)"
      p={6}
      borderRadius="lg"
      shadow="md"
      position="relative"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      height="400px" // Fixed height for consistency
      width="300px" // Limit the width
    >
      <Box
        bg={`url(${imageUrl})`}
        bgSize="contain"
        bgPosition="center"
        bgRepeat="no-repeat"
        borderRadius="md"
        boxShadow="md"
        mb={4}
        width="100%"
        height="100%" // Fixed height for images
      />
      <Heading as="h2" size="md" mb={4} color="black" textAlign="center">
        {title}
        <br />
        {bookNumber}
      </Heading>
      <Link href={link} isExternal color="black" target="_blank">
        <Text fontSize="lg" fontWeight="bold">
          Shop Now
        </Text>
      </Link>
    </Box>
  );
};

export default Shop;
