// src/components/MainPage.js
import React from "react";
import { Box, Flex, Heading } from "@chakra-ui/react";

const MainPage = () => {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      minHeight="100vh"
      color="white"
    >
      <Heading as="h1" size="2xl" mb={8} textAlign="center">
        Welcome to Liana Valerian's Hell
      </Heading>
      <Box
        as="video"
        src="/main.MP4"
        controls
        loop
        autoPlay
        muted
        width="80%"
        maxWidth="400px"
        borderRadius="md"
        boxShadow="xl"
        backgroundColor="rgba(0, 0, 0, 0.5)"
        p={2}
      />
    </Flex>
  );
};

export default MainPage;
